import { configureStore } from "@reduxjs/toolkit";
import counterReducer, { counterSlice } from "./slices/counter.slice";

console.log(counterSlice.name);
export const store = configureStore({
  reducer: {
    [counterSlice.name]: counterReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
