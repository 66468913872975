import React from "react";
import {
  increment,
  decrement,
  incrementByAmount,
} from "@redux/slices/counter.slice";
import { useAppDispatch, useAppSelector } from "@redux/hooks";

export default function CounterDemo() {
  const count = useAppSelector(state => state.counter.value);
  const dispatch = useAppDispatch();
  return (
    <div>
      <button
        aria-label="Increment value"
        onClick={() => dispatch(increment())}
      >
        Increment
      </button>
      <button
        aria-label="Increment value"
        onClick={() => dispatch(incrementByAmount(10))}
      >
        Increment
      </button>
      <span>{count}</span>
      <button
        aria-label="Decrement value"
        onClick={() => dispatch(decrement())}
      >
        Decrement
      </button>
    </div>
  );
}
