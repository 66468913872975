import React from "react";
import { Avatar, List } from "antd";
import "./App.css";
import { NavLink, Outlet, useNavigation } from "react-router-dom";
import { add } from "@services/test.service";

const data = [
  {
    title: "React Highcharts Guage Chart",
    path: "/guagechart",
    description: "Use high charts lib draw guage chart",
  },
  {
    title: "Counter Redux Demo",
    path: "/counterdemo",
    description: "use redux toolkit to manage the state",
  },
  {
    title: "Ant Design Title 3",
    path: "/counterdemo",
    description: "Ant Design Title 3",
  },
  {
    title: "Ant Design Title 4",
    path: "/counterdemo",
    description: "Ant Design Title 4",
  },
];

const App: React.FC = () => {
  React.useEffect(() => {
    add();
  }, []);
  let navigation = useNavigation();
  return (
    <div className="App">
      <div className="left">
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`}
                  />
                }
                title={<NavLink to={item.path}>{item.title}</NavLink>}
                description={item.description}
              />
            </List.Item>
          )}
        />
      </div>
      <div className="right">
        <Outlet />
      </div>
      <div style={{ position: "fixed", top: 0, right: 0 }}>
        {navigation.state !== "idle" && <p>Navigation in progress...</p>}
      </div>
    </div>
  );
};

export default App;
